<template>
  <b-card-code title="Advance Search Table">
    <div class="custom-search">
      <!-- advance search input -->
      <validation-observer ref="simpleRulesSearch">
        <b-form>
          <b-row>
            <b-col md="4">
              <label for="isp_id">Date Range</label>
                
        <flat-pickr
          v-model="rangeDate"
          class="form-control"
          :config="{ mode: 'range'}"
        />  
            </b-col>
            <b-col md="4">
              <label for="status">Status</label>
            
                <v-select
                  :options="statusOptions"
                  :reduce="(val) => val.value"
                  placeholder="Select Status"
                  v-model="status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                />
                
            </b-col> 
            <b-col md="4">
              <b-form-group>
                <label for=""> </label>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  @click.prevent="searchInvoiceSettlement"
                >
                  Search
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows" 
      :line-numbers="true"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      theme="my-theme"
    >
      <!-- Column: Actions -->
      <template slot="table-row" slot-scope="props">
 
        <span v-if="props.column.field == 'id'" class="text-nowrap">
          <span>{{ props.row.id }}</span>
        </span>
        <span v-if="props.column.field == 'name'" class="text-nowrap">
          <span>{{ props.row.name }}</span>
        </span>
        <span v-if="props.column.field == 'company_name'" class="text-nowrap">
          <span>{{ props.row.company_name }}</span>
        </span> 
        <span v-if="props.column.field == 'mobile'" class="text-nowrap">
          <span>{{ props.row.mobile }}</span>
        </span>
        <span
          v-if="props.column.field == 'countryCode'"
          class="text-nowrap"
        >
          <span>{{ props.row.countryCode }}</span>
        </span>
         <span
          v-if="props.column.field == 'total_isp_Ppp'"
          class="text-nowrap"
        >
          <span>{{ props.row.total_isp_Ppp }}</span>
        </span>
        
        <span
          v-if="props.column.field == 'created_at'"
          class="text-nowrap"
        >
          <span>{{ props.row.created_at }}</span>
        </span>
        <span
          v-if="props.column.field == 'invoice_status'"
          class="text-nowrap"
        >
         
          <span class="text-success" >{{ (props.row.invoice_status == 1) ? "Yes" : "No" }}</span>
        </span>
        <span
          v-if="props.column.field == 'status'"
          class="text-nowrap"
        >
         
          <span class="text-success" >{{ props.row.status   }}</span>
        </span> 
        <div class="text-nowrap" v-if="props.column.field == 'actions'">
           
           <feather-icon
            :id="`invoice-row-${props.row.id}-preview-icon`"
            icon="Edit2Icon"
            size="16"
            class="mx-1"
             @click="
              $router.push({
                name: 'account-setting',
                query: { isp_id: props.row.id },
              })
            "
          />  
          <div> 
          </div>
        </div>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['100', '300', '500','1000']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BLink,
  BTooltip,
  BMedia,
  BBadge,
  BButton,
  VBToggle,
  BSidebar,
  BForm,
  BFormTextarea,
} from "bootstrap-vue";
import { ISP_LIST, ADMIN_ISP_LIST } from "@core/services/api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
// import store from '@/store/index'
import store from "@/store";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import useJwt from "@/auth/jwt/useJwt";
import vSelect from "vue-select";
import { required, email } from "@validations";
import { checkRes } from "@core/services/helper";
// Use toast
import Mixin from "@core/services/mixin"; 

export default {
  mixins: [Mixin],
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BLink,
    BTooltip,
    BMedia,
    BBadge,
    BButton,
    BSidebar,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    const statusOptions = [
      { value: 1, label: "Active" }, 
      { value: 0, label: "Inactive" },
    ];
    const resolveClientAvatarVariant = (status) => {
      if (status === "Partial Payment") return "primary";
      if (status === "Inactive") return "danger";
      if (status === "Downloaded") return "secondary";
      if (status === "Draft") return "warning";
      if (status === "Sent") return "info";
      if (status === "Active") return "success";
      return "primary";
    };
    return {
      statusOptions,
      toggle: false,
      isShow: false,
      pageLength: 100,
      dir: false,
      required,
      columns: [ 
        {field:'id',label:"ISP ID:",sortable:true},
        { field: "name", label: "Owner Name", sortable: true },
        { field: "company_name", label: "Company Name", sortable: true }, 
        { field: "mobile", label: "Mobile", sortable: true },
        { field: "countryCode", label: "Country" },
        { field: "total_isp_Ppp", label: "Total User" },
        {
          field: "created_at",
          label: "Reg. Date",
          sortable: true, 
        }, 
        {
          field: "status",
          label: "Status"
        }, 
        {
          field: "invoice_status",
          label: "Invoice Status"
        },
        { field: "actions", label: "Action" },
      ],
      rows: [],
      invoice_ids: [],
      isp_list: [],
      isp_id: "",
      searchTerm: "",
      from_date: "",
      to_date: "",
      bank_trans_id: null,
      remakr: null,
      status: "",
      updateStatus: "",
      UpdateBankTransTd: null,
      settlementId: "",
      resolveClientAvatarVariant,
      rangeDate: null,
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.listSettlementData();
  },
  methods: {
    listSettlementData() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + ADMIN_ISP_LIST, {
          params: {},
        })
        .then((res) => {
          this.rows = res.data.data.isp_info;
        });
    },
    closeSideBar() {
      this.settlementId = null;
    },
    settlementUpdateIdSet(id) {
      this.settlementId = id;
    },
    searchInvoiceSettlement() {
      if (this.rangeDate) {
      let tempDateArry = this.rangeDate.split(" to ");
      this.from_date = tempDateArry[0] ?? null;
      this.to_date = tempDateArry[1] ?? null;
      } 
      this.$refs.simpleRulesSearch.validate().then((success) => {
        if (success) {
          this.$http
            .get(process.env.VUE_APP_BASEURL + ADMIN_ISP_LIST, {
              params: {
                status: this.status, 
                from_date: this.from_date,
                to_date: this.to_date,
              },
            })
            .then((res) => {
              this.rows = res.data.data.isp_info;
            });
        }
      });
    },
    idpListData() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + ISP_LIST)
        .then((res) => {
          this.isp_list = res.data.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            userData.value = undefined;
          }
        });
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          useJwt
            .updateSettlement({
              settlementId: this.settlementId ?? null,
              status: this.updateStatus ?? null,
              bank_trans_id: this.UpdateBankTransTd ?? null,
              remark: this.remakr ?? null,
            })
            .then((response) => {
              this.listSettlementData();
              if (checkRes(response.status) && response.data.data) {
                this.isShow = false;
                this.$router
                  .push({ name: "admin-settlement-list" })
                  .catch((success) => {
                    this.toastMessage("success", "Invoice Payment", response);
                  });
              } else {
                this.$router
                  .push({ name: "admin-settlement-list" })
                  .then(() => {
                    this.toastMessage("warning", "Invoice Payment", response);
                  })
                  .catch((error) => {
                    this.toastMessage("danger", "Invoice Payment", response);
                  });
              }
            });
        }
      });
    },
  },
  mounted() {
    this.idpListData();
  },
};
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
